<template>
  <div style="min-height: 750px">
    <div class="bgc_w pt-4 pb-4">
      <div class="pl-4 pr-4">
        <div class="title">
          <img
            src="@/assets/img/head/icon.png"
            style="margin-bottom: 5px; margin-right: 5px"
          />
          订单管理
        </div>
        <!-- <div class="row color-4d mt-4 justify-content-lg-start">
          <div class="row">
            <div class="row align-items-center mr-2">
              <span class="mr-2">支付状态</span>
              <el-select
                size="small"
                v-model="status"
                class="br_4 cursor_pionter wh-120-34 pl-1"
                @change="onPageChange(1)"
              >
                <el-option :value="''" label="全部"></el-option>
                <el-option :value="1" label="支付"></el-option>
                <el-option :value="0" label="未支付"></el-option>
              </el-select>
            </div>
            <div class="row align-items-center">
              <span class="mr-2">支付方式</span>
              <el-select
                size="small"
                v-model="pay"
                class="br_4 cursor_pionter wh-120-34 pl-1"
                @change="onPageChange(1)"
              >
                <el-option :value="''" label="全部"></el-option>
                <el-option :value="1" label="支付宝">支付宝</el-option>
                <el-option :value="0" label="微信"></el-option>
              </el-select>
            </div>
            <div class="row align-items-center ml-2">
              <span class="mr-2">时间</span>
              <el-date-picker
                @change="change"
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
        </div> -->
        <el-table
          stripe
          style="width: 98%; margin: 20px 0"
          :data="showOrders"
          :maxHeight="tableContentHeight"
          :header-cell-style="{
            background: '#E9F3FF',
            color: '#333',
            height: '48px',
          }"
        >
          <el-table-column width="120" prop="name" label="序号">
            <template slot-scope="{ $index }">
              {{ $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="order_no" label="订单编号"> </el-table-column>
          <el-table-column prop="account_type" label="用户类型">
            <template slot-scope="{ row }">
              <span>
                {{ row.account_type == 0 ? "普通用户" : "专业用户" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="recharge_times" label="充值次数">
            <template slot-scope="{ row }">
              {{ row.recharge_times == 0 ? "--" : row.recharge_times }}
            </template>
          </el-table-column>
          <el-table-column prop="recharge_amount" label="有效期间">
            <template slot-scope="{ row }">
              {{ row.start_validity.substring(0, 10) }} ~
              {{ row.end_validity.substring(0, 10) }}
            </template>
          </el-table-column>
          <el-table-column prop="industry_type" label="支付时间">
            <template slot-scope="{ row }">
              {{ row.pay_time.substring(0, 10) }}
              {{ row.pay_time.substring(11, 16) }}
            </template>
          </el-table-column>
        </el-table>
        <div class="text-center">
          <v-pagination
            :total="totalOrderNum"
            :pageSize="10"
            @page-change="onPageChange"
            :layout="['total', 'prev', 'pager', 'next', 'jumper']"
            size="middle"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
import {
  orders,
  getOrders,
  CheckUserPower,
  alipay,
  wxpay,
  wx_payresult,
} from "@/api";

export default {
  data() {
    return {
      showOrders: [],
      page: 1,
      totalOrderNum: 0,
      pay: "",
      status: "",
      value1: [],
      tableContentHeight:
        window.innerHeight -
        60 -
        50 -
        32 -
        20 -
        36 -
        24 -
        20 -
        10 -
        20 -
        20 -
        10 -
        10,
    };
  },
  mounted() {
    this.queryOrder();
    // CheckUserPower().then((res) => {
    //   const { data } = res;
    //   if (data.status == 200) {
    //     if (sessionStorage.userData) {
    //       var userData = JSON.parse(sessionStorage.userData);
    //       userData.user_role = data.msg;
    //       this.$store.dispatch("addLoginUser", userData);
    //     }
    //     this.$store.commit("setPowerUser", data.msg);
    //   } else {
    //     this.$Message.warning(data.msg);
    //   }
    // });
  },
  watch: {
    // value1() {
    //   this.onPageChange(1);
    // },
  },
  methods: {
    queryOrder() {
      let user = sesstion.getLocalData("userData").user_code;
      let param = {
        user_code_id: user,
        page_size: 10,
        page: this.page,
      };

      getOrders(param).then(({ data }) => {
        this.showOrders = data.results;
        this.totalOrderNum = data.count;
      });
    },
    change(val) {
      // console.log(val);
      this.onPageChange(1);
    },
    onPageChange(page) {
      this.page = page;
      this.queryOrder();
    },
    onApplyInvoice(order) {
      // console.log(order);
      if (order.pay == "支付宝") {
        // 支付宝
        alipay({
          item_name: order.item_name,
          item_discribe: "充值" + order.item_name,
          price: order.item_name,
          order_num: order["item_code"],
          return_url:
            location.href.replace(this.$route.path, "") +
            "/CenterIndex/OrderManage",
        })
          .then(({ data }) => {
            if (data.status === 200) {
              window.open(data.data, "_blank");
              this.showPayModal = true;
              wx_payresult(data.order_num);
            }
          })
          .catch((err) => {
            this.$Message.error(JSON.stringify(err));
            this.showPayModal = true;
          });
      } else {
        //微信
        wxpay({
          item_name: "" + order.item_name,
          item_discribe: "充值" + order.item_name,
          price: +order.item_name,
          order_num: order["item_code"],
          return_url:
            location.href.replace(this.$route.path, "") +
            "/CenterIndex/OrderManage",
        }).then((res) => {
          sessionStorage.wxpayData = JSON.stringify({
            rc: res.data.url,
            price: order.item_name,
          });

          window.open(`/#/wxpay`, "_blank");
        });
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  padding-left: 0px;
}
.text-center {
  display: flex;
  justify-content: flex-end;
}
@import "../../assets/css/table/defineTable.css";
.table {
  text-align: center;
}
.apply-invoice {
  color: #1172fe;
}
</style>
